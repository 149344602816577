import { eachDayOfInterval, endOfWeek, format, startOfWeek } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import type { Maybe, OnAirShowFragment } from "@ihr-radioedit/inferno-webapi";
import type { Store } from "@inferno/renderer-shared-core";

export interface OnAirScheduleInterface {
  siteTimeZone: string;
  selectedDay: DaysOfWeekInterface;
  current?: Maybe<OnAirShowFragment>;
  schedule: OnAirShowFragment[];
}

export interface ScheduleDayInterface {
  offset: number;
  day: DaysOfWeekInterface;
}

export interface DaysOfWeekInterface {
  short: string;
  full: string;
  timestamp: number;
}

const daysOfWeekMap = () => {
  const now = new Date();
  const thisWeek = eachDayOfInterval({
    start: startOfWeek(now),
    end: endOfWeek(now),
  });

  const data: DaysOfWeekInterface[] = [];
  const map = thisWeek.reduce((a, b) => {
    a.push({
      short: format(b, "EEEEEE").toLowerCase(),
      full: format(b, "EEEE").toLowerCase(),
      timestamp: b.valueOf(),
    });
    return a;
  }, data);
  const sunday = map.shift();
  if (sunday) {
    map.push(sunday);
  }
  return map;
};

export const daysOfWeek = daysOfWeekMap();

export const getCurrentDay = (timeZone: string, now = Date.now(), selectedDay?: string): ScheduleDayInterface => {
  const today = utcToZonedTime(new Date(now), timeZone);
  const offset = today.getDay();
  if (!selectedDay) {
    selectedDay = format(today, "EEEE").toLowerCase();
  }
  const selectedDayOfWeek = daysOfWeek.find(day => day.full === selectedDay) || daysOfWeek[0];
  // set the day of the week
  return {
    offset,
    day: selectedDayOfWeek,
  };
};

export const getLiveUrl = (store: Store) => {
  if (!store.site.sections?.partners) {
    return "";
  }

  const { partners } = store.site.sections;
  const streamId = store.site.getStreamId();
  if (partners.iheartradio_switch && streamId) {
    return `${store.env.NATIONAL_SITE_URL}/live/${streamId}`;
  }
  return "";
};
